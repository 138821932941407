.scale {
  animation-name: onscale;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes onscale {
  to {
    transform: scale(1.05);
  }
}
